import React, { useState } from "react";
import {
    Input,
    Button,
    Stack,
    Text,
    FormControl,
    FormErrorMessage,
    useToast,
    CircularProgress,
    Flex,
    Center,
    Box,
    Image,
    Heading
} from "@chakra-ui/react";
import { useAuth } from "./providers/AuthProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const { login, auth } = useAuth();
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const validate = () => {
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
        setLoginError();
        setErrors({});
        if (loginForm.email === undefined || loginForm.email === "") {
            setErrors({ ...errors, email: "Email is required" });
            return false;
        } else if (!reg.test(loginForm.email)) {
            setErrors({ ...errors, email: "Must be an email" });
            return false;
        }

        if (loginForm.password === undefined || loginForm.password === "") {
            setErrors({ ...errors, password: "Password is required" });
            return false;
        }
        return true;
    };

    const loginUser = async (email, password) => {
        try {
            setLoading(true);
            const {
                error,
            } = await login(email, password);
            if (error) {
                setLoginError(error.message);
            } else {
                toast({
                    title: "Login Successful",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoginError(error.message);
        }
    };

    useEffect(() => {
        if (auth) {
            navigate("/");
        } else {
            console.log("logout");
        }
    }, [auth]);

    const handleLogin = () => {
        if (validate()) {
            setLoginError("");
            setLoading(true);
            setTimeout(() => {
                loginUser(loginForm.email, loginForm.password);
                setLoading(false);
            }, 2000);
        }
    };

    return (
        <Box h="100vh" w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"}>
            <Center maxW={"90%"} width={500} pt={10} pb={20} px={20} bg={"transparent"}>
                <Stack spacing="4" width="100%">
                    <Center mb={8}>
                        <Image
                            boxSize='150px'
                            objectFit='contain'
                            src='../src/sozcode-logo-1.png'
                        />
                    </Center>
                    <Center mb={3}>
                        <Heading
                            size={{
                                base: 'xs',
                                md: 'sm',
                            }}
                            fontFamily={"Tiempos Headline"}
                        >
                            Log in to your account
                        </Heading>
                    </Center>
                    <FormControl
                        isRequired
                        alignContent="center"
                        isInvalid={"email" in errors || "password" in errors}
                    >
                        <Text fontSize={"sm"} mb={1}>Email</Text>
                        <Input
                            id="email"
                            type="text"
                            placeholder="Email"
                            value={loginForm.email}
                            onChange={(e) =>
                                setLoginForm({ ...loginForm, email: e.target.value })
                            }
                            mb={4}
                        />
                        {"email" in errors && (
                            <FormErrorMessage fontSize="xs">{errors.email}</FormErrorMessage>
                        )}
                        <Text fontSize={"sm"} mb={1}>Password</Text>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={loginForm.password}
                            onChange={(e) =>
                                setLoginForm({ ...loginForm, password: e.target.value })
                            }
                        />
                        {"password" in errors && (
                            <FormErrorMessage fontSize="xs">
                                {errors.password}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    {loginError && (
                        <Text mt="3" fontSize="10" color="red.600">
                            {loginError}
                        </Text>
                    )}
                    {isLoading ? (
                        <Flex justifyContent="center" alignItems="center" mt="4" mb="4">
                            <CircularProgress isIndeterminate size="35px" />
                        </Flex>
                    ) : (
                        <Button
                            onClick={handleLogin}
                            mt="4"
                            mb="4"
                            width="full"
                            height="35"
                            isLoading={isLoading}
                        >
                            Login
                        </Button>
                    )}
                </Stack>
            </Center>
        </Box>
    );
};

export default LoginPage;
