import React, {useEffect, useRef, useState} from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {
    Alert,
    Box,
    Button,
    Center,
    Circle, Container, Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack, Input, Radio, RadioGroup,
    Select, Spinner,
    Stack, Tab, TabList, TabPanel, TabPanels, Tabs,
    Text,
    AlertIcon, useToast
} from "@chakra-ui/react";
import {HiCheck, HiOutlineDocument, HiDocumentText, HiAnnotation, HiLightBulb, HiPaperAirplane} from 'react-icons/hi'

import {CheckCircleIcon, Icon} from "@chakra-ui/icons";
import SignaturePad from "react-signature-canvas";


const Step1 = (props) => {
    const {courseRuns, formData, setFormData, allowedTab, setTabIndex} = props

    const navigate = useNavigate();
    const location = useLocation();

    // Update form state based on URL params when the component mounts
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setFormData({
            registration_type: params.get('registration_type') || '',
            course_run_id: params.get('course_run_id') || '',
        });
    }, [location.search]);

    // Function to update form data and sync with URL params
    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        const params = new URLSearchParams(location.search);
        params.set(name, value);
        navigate({ search: params.toString() }, { replace: true });
    };


    return (
        <Box w={"100%"}>
            <FormControl>
                <FormLabel>Registration Type</FormLabel>
                <Select name="registration_type" value={formData.registration_type} placeholder='Select option' bg={"white"} onChange={(e) => handleChange("registration_type", e.target.value)}>
                    <option value={"Self-Sponsored"} label={"Self-Sponsored"}></option>
                    <option value={"Company-Sponsored"} label={"Company-Sponsored"}></option>
                    <option value={"HRP"} label={"HRP Registration (For Public Officers)"}></option>
                </Select>
            </FormControl>
                <FormControl mt={5}>
                    <FormLabel>Course Run</FormLabel>
                    <RadioGroup onChange={(value) => handleChange("course_run_id", value)} value={formData.course_run_id}>
                        <Stack direction='column'>
                            {courseRuns.map(each => (
                                    <Radio mt={3} name="course_run_id" key={each.attendance_id} value={each.attendance_id} label={`${each.name}`}>
                                        <Text>{each.name}</Text>
                                        <Text color={"gray.600"} fontSize={"xs"}>Start Date: {each.start_date}</Text>
                                        <Text color={"gray.600"} fontSize={"xs"}>End Date: {each.start_date}</Text>
                                        <Text color={"gray.600"} fontSize={"xs"}>Course Fees: SGD {each.price}</Text>
                                    </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>

                    <Button  mt="4" mb="8" size={"lg"} isDisabled={allowedTab < 1} onClick={() => setTabIndex(1)}>
                        Next
                    </Button>
                </FormControl>
        </Box>
    )
}

const Step2 = (props) => {
    const {setFormData, formData, allowedTab, setTabIndex} = props

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const registration_type = params.get("registration_type")

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <Box>
            {registration_type === "Self-Sponsored"
                ?
                <Box>
                    <FormControl mt={5}>
                        <FormLabel>Participant Name</FormLabel>
                        <Input
                            value={formData.participant_name}
                            onChange={(e) => handleChange("participant_name", e.target.value)}
                            size='sm'
                            type={"text"}
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Email</FormLabel>
                        <Input
                            value={formData.participant_email}
                            onChange={(e) => handleChange("participant_email", e.target.value)}
                            size='sm'
                            type={"email"}
                        />
                    </FormControl>
                </Box>
                :
                <Box>
                    <FormControl mt={5}>
                        <FormLabel>Participant Name</FormLabel>
                        <Input
                            value={formData.participant_name}
                            onChange={(e) => handleChange("participant_name", e.target.value)}
                            size='sm'
                            type={"text"}
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Email</FormLabel>
                        <Input
                            value={formData.participant_email}
                            onChange={(e) => handleChange("participant_email", e.target.value)}
                            size='sm'
                            type={"email"}
                        />
                    </FormControl>
                </Box>
            }
            <Button  mt="4" mb="8" size={"lg"} isDisabled={allowedTab < 2} onClick={() => setTabIndex(2)}>
                Next
            </Button>
        </Box>
    )
}

const Checkout = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props

    return (
        <Box>
            <Spinner/>
            <Text>Please wait as we redirect you to checkout</Text>
        </Box>
    )
}

const Step3 = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props
    const [submitting, setSubmitting] = useState(false)
    console.log(sigPad.current?.isEmpty())
    const clearSignature = () => {
        sigPad.current.clear();
    };
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const course_run_id = params.get("course_run_id")
    const selected_course_run = courseRuns.filter((each) => each.attendance_id === course_run_id)[0]
    const toast = useToast()

    const insertRegistration = async () => {
        setSubmitting(true);
        try {
            const { data, error } = await supabase
                .from('registration')
                .insert([
                    {
                        participant_name: formData?.participant_name,
                        participant_email: formData?.participant_email,
                        billing_type: formData?.billing_type,
                        registration_type: formData?.registration_type,
                        signature: formData?.signature,
                        course_run_id: selected_course_run.id,
                        registration_stage: "pending"
                    },
                ])
                .select("id, participant_email")
                .single();

            if (error) throw error;

            return data;
        } catch (error) {
            console.error('Error inserting registration:', error);
            setSubmitting(false);
            throw error; // Re-throw error so it can be caught later
        }
    };

    const handleCheckout = async (registration) => {
        console.log(registration)
        try {
            const response = await fetch('https://eovhmnu1acpextc.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: selected_course_run.course_type_code,
                    registration_id: registration.id,
                    participant_email: registration.participant_email
                }),
            });

            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
                setSubmitting(false);
            } else {
                console.error('No URL found in response');
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
        }
    };

    const saveSignature = async () => {
        if (sigPad.current?.isEmpty()) {
            toast({
                title: "Sign the student contract before proceeding to checkout",
                status: "error",
                isClosable: true,
                position: "top",
            });
        } else {
            try {
                if (sigPad.current) {
                    const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
                    console.log(dataURL);

                    // Update formData with the signature
                    setFormData((prevData) => ({
                        ...prevData,
                        signature: dataURL,
                    }));

                    // Insert the registration and await its result
                    const registrationData = await insertRegistration();

                    if (registrationData) {
                        // Proceed to checkout with the registration data
                        await handleCheckout(registrationData);
                    }
                }
            } catch (error) {
                console.error('Error in saveSignature process:', error);
            }
        }
    };

    return (
        <div>
            <Alert status='info' mb={8}>
                <AlertIcon />
                Please view the terms of the Student Contract carefully before proceeding to checkout
            </Alert>
                <Text fontSize={"lg"} as={"b"}>STUDENT CONTRACT (FOR SELF-SPONSORED)</Text>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            This contract binds the Training Provider and the Student once both parties sign this Contract.
            This contract is made between:
                <br/>
                <br/>
                <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registered Name of Training Provider:
                            </Text>
                            <Text>SOZCODE PTE. LTD</Text>
                            Registration Number: 202139596G<br/>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registration Number:
                            </Text>
                            <Text>202139596G</Text>
                        </HStack>
                    </li><br/>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Student Name:
                            </Text>
                            <Text>{formData?.participant_name}</Text>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Email:
                            </Text>
                            <Text>{formData?.participant_email}</Text>
                        </HStack>

                    </li>
                </ol>
                <br/>
                <br/>
           <strong>Course Information</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Title:
                </Text>
                <Text>{selected_course_run?.name}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Commencement Date:
                </Text>
                <Text>{selected_course_run?.start_date}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course End Date:
                </Text>
                <Text>{selected_course_run?.end_date}</Text>
            </HStack>
            <br/>
            <br/>
                <strong>Course Fees</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>
                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Fee Breakdown</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Amount</td>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr style={{border: "1px solid black"}}>
                            <td style={{border: "1px solid black"}}>
                                Full Course Fee
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: "1px solid black"}}>
                                Nett fee payable by individual
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <strong>Refund/Withdrawal and Rescheduling Policy</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>


                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Request for Withdrawal</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Options for student</td>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{border: "1px solid black"}}>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made before sending of course confirmation email
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Cancel with no cancellation charges
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made at least 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Re-scheduling for other course runs (if available)<br/>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made less than 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            <br/>
                <br/>

            <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>The Training Provider reserves the right to change or cancel the course due to unforeseen circumstances. A full refund of any course fees paid will be made to the Student in this circumstance.

                    </li><br/>
                    <li>A withdrawal request can be made via sending an email to courses@sozcode.com.

                    </li><br/>
                </ol>
            <br/>
            <br/>
            <FormLabel>            I hereby acknowledge and agree to the terms stated in this Contract.
            </FormLabel>
            <Box w={300}>
                <SignaturePad ref={sigPad} canvasProps={{ className: 'signatureCanvas', height: '300px', width: '400px' }} />
            </Box>
            <Box>
                <Button mt="1" mb="8" maxW={200} minW={100}  w="20%" size={"xs"} variant={"outline"} onClick={clearSignature}>
                    Clear Signature
                </Button>
            </Box>
            <Box>
                <Button  mt="4" mb="8" size={"lg"} isLoading={submitting} onClick={saveSignature}>
                    Proceed to Payment
                </Button>
            </Box>
        </div>
    )
}

export const Registration = () => {
    const [courseRuns, setCourseRuns] = useState([]);
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const [loadingCourseRuns, setLoadingCourseRuns] = useState(false)
    const { courseRunId } = useParams();
    const sigPad = useRef(null);
    const [formData, setFormData] = useState({
        participant_name: '',
        participant_email: '',
        registration_type: '',
        course_run_id: '',
        signature: ''
    });

    const [tabIndex, setTabIndex] = useState(0)
    const [allowedTab, setAllowedTab] = useState(0)

    const handleTabsChange = (index) => {
        setTabIndex(index)
    }



    useEffect(() => {
        const steps = [
            ['course_run_id', 'registration_type'],                       // Step 1
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email'],  // Step 2
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email', 'signature'] // Step 3
        ];

        let allowedTab = 0;

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].every(field => formData[field])) {
                allowedTab = i + 1;
            } else {
                break;
            }
        }
        setAllowedTab(allowedTab);
        console.log(allowedTab, formData)

    }, [formData])

    const getAllCourseRuns = async () => {
        setLoadingCourseRuns(true)
        const { data, error } = await supabase
            .from("course_run_details")
            .select("*")
            .gte("start_date", new Date().toLocaleDateString('fr-CA'))
            .order("start_date")

        if (data) {
            console.log(data);
            setCourseRuns(data);
            setLoadingCourseRuns(false)
        }
        if (error) {
            console.log(error);
            setLoadingCourseRuns(false)
        }
    };



    useEffect(() => {
        getAllCourseRuns();
    }, []);




    return (
            <Container w={"100%"}  display="flex" justifyContent="center" alignItems="center" >
                {
                    loadingCourseRuns
                        ?
                        <Spinner />
                        :
                        <Stack spacing="4" width="100%" bg={"white"} maxW={"95%"} px={10} py={10}>
                            <Heading size={"sm"} mb={10}>
                                Course Registration
                            </Heading>
                            <Tabs index={tabIndex} onChange={handleTabsChange}>
                                <TabList>
                                    <Tab isDisabled={allowedTab <= 0}>1. Registration</Tab>
                                    <Tab isDisabled={allowedTab < 1}>2. Participant Details</Tab>
                                    <Tab isDisabled={allowedTab < 2}>3. Student Contract</Tab>
                                    <Tab isDisabled={allowedTab < 3}>4. Checkout</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Step1 courseRuns={courseRuns} formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <Step2 formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <Step3 sigPad={sigPad} formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <Checkout formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Stack>
                }
            </Container>
    );
};
