import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {Box, Button, Center, FormControl, FormLabel, Heading, Select, Stack, Text} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import SignaturePad from "react-signature-canvas";

export const Attendance = () => {
    const [attendees, setAttendees] = useState([]);
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const { courseRunId } = useParams();
    const sigPad = useRef(null);

    const getAllAttendees = async () => {
        const { data, error } = await supabase
            .from("registration")
            .select("participant_name, id, course_run_details!inner(*)")
            .eq("course_run_details.attendance_id", courseRunId);

        if (data) {
            console.log(data);
            setAttendees(data);
        }
        if (error) {
            console.log(error);
        }
    };

    const insertAttendance = async(signature) => {
        setSubmitting(true)
        const { data, error } = await supabase
            .from('attendance')
            .insert([
                { attendance_id: courseRunId, registration_id: name, signature: signature },
            ])
            .select()
            .single()
        if (data) {
            setSubmitting(false)
            setSuccess(data)
            console.log(data)
        }

        if (error) {
            console.log(error)
            setSubmitting(false)
        }

    }

    useEffect(() => {
        getAllAttendees();
    }, [courseRunId]);

    const clearSignature = () => {
        sigPad.current.clear();
    };

    const saveSignature = () => {
        if (sigPad.current) {
            const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            console.log(name)
            console.log(dataURL);
            insertAttendance(dataURL)
            // You can now send this dataURL to your server or use it as needed
        }
    };

    return (
        success
            ?
            <Box w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"}>
                <Center maxW={"90%"} width={800} pt={10} pb={20}>
                    <Stack spacing="4" width="100%" textAlign={"center"}>
                        <Heading size={"sm"}>Submission success</Heading>
                        <Center>
                            <CheckCircleIcon boxSize={15} bg={"green"}/>
                        </Center>
                        <Text>Attendance received from {attendees.filter(each => each.id === success.registration_id)[0].participant_name} at {new Date(success.created_at).toLocaleString("en-SG", { timeZone: "Asia/Singapore" })}</Text>

                    </Stack>
                </Center>
            </Box>
            :
            <Box w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"}>
                <Center maxW={"90%"} width={800} pt={10} pb={20}>
                    <Stack spacing="4" width="100%">
                        <Heading size={"sm"}>
                            Attendance For {attendees[0]?.course_run_details?.name} ({attendees[0]?.course_run_details?.start_date} - {attendees[0]?.course_run_details?.end_date})
                        </Heading>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Select value={name} placeholder='Select option' bg={"white"} onChange={(e) => setName(e.target.value)}>
                                {attendees.map(each => (
                                    <option key={each.id} value={each.id} label={each.participant_name}></option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormLabel>Signature</FormLabel>
                        <SignaturePad ref={sigPad} canvasProps={{ className: 'signatureCanvas', height: '300px' }} />
                        <Button mt="1" mb="8" maxW={200} minW={100}  w="20%" size={"xs"} variant={"outline"} onClick={clearSignature}>
                            Clear Signature
                        </Button>
                        <Center>
                            <Button isLoading={submitting} mt="4" mb="8" bg={"#F17946"} maxW={400} minW={200} w="50%" size={"lg"} onClick={saveSignature}>
                                Submit
                            </Button>
                        </Center>
                    </Stack>
                </Center>
            </Box>
    );
};
