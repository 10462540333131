/* eslint-disable */

import logo from "./logo.svg";
import "./App.css";
import {Routes, Route, Outlet, Link, Navigate, useParams, useLocation} from "react-router-dom";
import AuthProvider from "./providers/AuthProvider";
import {useAuth} from "./providers/AuthProvider";
import LoginPage from "./LoginPage";
import {Registration}from "./Registration"
import SignaturePad from 'react-signature-canvas'

import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Center,
    FormControl,
    Heading,
    HStack,
    Image,
    Select,
    Stack,
    Text,
    FormLabel,
    Spinner, Badge, Alert, AlertIcon
} from "@chakra-ui/react";
import {supabase} from "./utils/supabaseClient";
import {CheckCircleIcon} from "@chakra-ui/icons";
import {Attendance} from "./Attendance";

const Home = () => {
    return (
        <Heading>Test</Heading>
    )
}

const Courses = () => {
    return (
        "Courses"
    )
}

const ProtectedRoute = ({
                            redirectPath = '/login',
                            children,
                        }) => {
    const {auth, isLoading} = useAuth()
    console.log(auth)
    if (auth && !isLoading) {
        return children;
    } else if (!isLoading) {
        return <Navigate to={redirectPath} replace/>;
    }

};

const Acknowledgement = () => {
    const [registration, setRegistration] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const { registrationId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const payment_stage = params.get("session_id")
    const getRegistration = async () => {
        const { data, error } = await supabase
            .from("registration")
            .select("participant_name, id, participant_email, registration_stage, course_run_details!inner(*)")
            .eq("id", registrationId)
            .single()

        if (data) {
            console.log(data);
            setRegistration(data);
        }
        if (error) {
            console.log(error);
        }
    };

    const handleCheckout = async () => {
        setSubmitting(true)
        try {
            const response = await fetch('https://eovhmnu1acpextc.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: registration.course_run_details.course_type_code,
                    registration_id: registrationId,
                    participant_email: registration.participant_email
                }),
            });

            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
                setSubmitting(false);
            } else {
                console.error('No URL found in response');
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
        }
    };

    useEffect(() => {
        getRegistration()
    }, [])

    return (
        registration === null
            ?
            <Spinner/>
            :
            <Box w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"} m={"auto"}>
            <Center maxW={"90%"} width={800} pt={10} pb={20}>

                <Stack spacing="4" width="100%">
                    <Heading size={"sm"} mb={2}>{registration.registration_stage === "confirmed" || payment_stage !== "failed" ? "Course Confirmation" : "Registration Details - Pending Payment"}</Heading>
                    <Alert status='warning' mb={5}>
                        <AlertIcon />
                        {registration.registration_stage === "confirmed" || payment_stage !== "failed" ? `You will receive a course confirmation email sent to ${registration.participant_email} shortly. Please reach out to courses@sozcode.com if you did not receive the email.` : "This is not a confirmation of your registration. Please click on the checkout button below to complete your checkout."}
                    </Alert>
                    {
                        registration.registration_stage === "confirmed" || payment_stage !== "failed"
                            ? null
                            : <Button onClick={handleCheckout} isLoading={submitting}>Proceed to checkout</Button>
                    }

                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Registration Stage
                        </Text>
                        <Box>
                            <Badge colorScheme={registration.registration_stage === "confirmed" || payment_stage !== "failed" ? 'green' : 'red'}>{registration.registration_stage === "confirmed" || payment_stage !== "failed" ? "Payment Received" : "Pending Payment"}</Badge>
                        </Box>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Participant Name
                        </Text>
                        <Text fontWeight={600}>{registration.participant_name}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course Title
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.name}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course Start Date
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.start_date}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course End Date
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.end_date}</Text>
                    </HStack>
                    <Stack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            <u>Venue</u>
                        </Text>
                        <Text fontWeight={400}>{registration.course_run_details.venue}<br/><br/>{registration.course_run_details.attachment}</Text>
                    </Stack>
                    <Text w={200} color="fg.muted" fontWeight={400}>
                        <u>Pre-course brief</u>
                    </Text>
                    <Text fontWeight={400}>
                        {registration.course_run_details.admin_brief}                    </Text>
                </Stack>
            </Center>
        </Box>
    )
}

const protected_routes = [
    {
        path: '/home',
        element:
            <ProtectedRoute>
                <Home/>
            </ProtectedRoute>
    },
    {
        path: '/my-courses',
        element:
            <ProtectedRoute>
                <Courses/>
            </ProtectedRoute>
    }
]

function App() {

    return (
            <AuthProvider>
                <Box bg={"#F4EEEA"}>
                    <Routes>
                        {
                            protected_routes.map(route =>
                                <Route key={route.path} path={route.path} element={route.element}/>
                            )
                        }
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/confirmation/:registrationId" element={<Acknowledgement/>}/>
                        <Route path="/attendance/:courseRunId" element={<Attendance/>}/>
                        <Route path="/registration" element={<Registration/>}/>
                    </Routes>
                </Box>
            </AuthProvider>
    );
}

function RouterApp() {
    const {isLoading} = useAuth()
    console.log(isLoading)
    if (isLoading) {
        return (
            <div>
                Loading
            </div>
        )
    } else {
        return (
            <Outlet/>
        )
    }
}

export default App;
