import { createClient } from "@supabase/supabase-js";
const projectURL = "https://yvpmakjfsvzjewrsskcj.supabase.co"
const projectKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl2cG1ha2pmc3Z6amV3cnNza2NqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzMjc2NjQsImV4cCI6MjAxNjkwMzY2NH0.EiEYbVLrabEun7KHtI_ulF2O88eEqVUSQ5XpjfEjpgE"

//const projectURL = "http://127.0.0.1:54321";
//const projectKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0\n"

export const supabase = createClient(projectURL, projectKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});
