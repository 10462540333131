import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";

const AuthContext = createContext({
  user: null,
  auth: false,
  profile: null,
  isLoading: true,  // Set initial loading state to true
  login: () => {},
  updateProfile: () => {},
  signOut: () => {},
  switchClass: () => {},
});
export const useAuth = () => useContext(AuthContext);

const login = async (email, password) => {
  return supabase.auth.signInWithPassword({ email, password });
};

const signOut = () => supabase.auth.signOut();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        console.log("Authentication check timeout");
        setAuth(false);
        setLoading(false);
      }
    }, 10000); // Set timeout to 10 seconds

    const { data } = supabase.auth.onAuthStateChange(async (event, session) => { // Make the callback async
      clearTimeout(timeoutId); // Clear timeout if auth state changes
      console.log(session);
      if (session?.user) {
        setUser(session.user);
        setAuth(true);
      } else {
        setUser(null);
        setAuth(false);
      }
      setLoading(false);
    });

    return () => {
      clearTimeout(timeoutId);
      data.subscription?.unsubscribe();
    };
  }, []);

  return (
      <AuthContext.Provider value={{
        user,
        auth,
        isLoading,
        login,
        signOut,
      }}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;

